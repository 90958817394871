import { put, select, takeEvery } from 'redux-saga/effects';

// import Helper
import { PushAPIHelper } from '../_helper/pushapi.helper';

// import Reducer
import { ServerPropsInterface } from '../reducers/Server';

const ServerParam = (state: ServerPropsInterface)   => state.Server;

// Root Saga登録配列
export const RootPushAPIAction = [
    takeEvery('PushAPIAction/setServiceWorker' , setServiceWorker),
    takeEvery('PushAPIAction/subscribe' , Subscribe),
    takeEvery('PushAPIAction/pushNotification' , PushNotification),
    takeEvery('PushAPIAction/pushAllNotification' , PushAllNotification),
    takeEvery('PushAPIAction/unSubscribe' , UnSubscribe),
    takeEvery('PushAPIAction/removeAllSubscription' , RemoveAllSubscription),
    takeEvery('PushAPIAction/setUserStatus' , setUserStatus),
    takeEvery('PushAPIAction/test' , test),
];

export function* test(): any
{
    var options = {
        body: 'unko',
        //icon: ''
    }
    yield new Notification('unkoman', options);
}

/**
 * StatusReducerに情報を追加
 * @param st string
 * @param time number
 * @param body string
 */
function* addStatus(st: string, time: number, body: string): any
{
    yield put({
        type    : 'State/addState',
        State   : {
            status  : st,
            time    : time,
            body    : body
        }
    });
}

/**
 * ServiceWorkerを登録
 * @returns 
 */
export function* setServiceWorker(): any
{
    if (yield PushAPIHelper.call().setServiceWorker()) {
        console.log(`
        [Service Worker] Regist Success
        `);
        yield put({
            type    : 'Worker/toggleRegister',
            register: true,
        });
        yield addStatus(
            'info', PushAPIHelper.call().getNow(),'Service Worker Regist Success');
        return;
    };
    console.error(`
        [Service Worker] Regist Failed
    `);

    yield addStatus(
        'Error', PushAPIHelper.call().getNow(), 'Service Worker Regist Faild');
}

/**
 * サーバーから公開鍵を取得
 */
export function* getServerKey(): any
{
    const key = yield PushAPIHelper.call()
                        .getServerKey()
                        .then((responce: Response) => responce.json());
    console.log(key);

    yield addStatus(
        'info', PushAPIHelper.call().getNow(), JSON.stringify(key));

    yield put({
        type    : 'Server/setKEY',
        KEY     : key['publickey']
    });
}

/**
 * Subscriptionをサーバーに登録
 * @param val 
 */
export function* Subscribe(val: any): any
{
    console.log('Setup PushAPI');
    yield getServerKey();

    const p = yield select(ServerParam);
    yield PushAPIHelper.call().setup(p.KEY,p.url);
}

/**
 * ユーザー情報の更新
 */
export function* setUserStatus(): any
{
    const p = yield select(ServerParam);
    yield PushAPIHelper.call().updateUser({
        name    : p.name,
        mail    : p.mail,
        group   : p.group
    });
}

/**
 * Push通知(自分向け)の送信要求を投げる
 * @param val 
 */
export function* PushNotification(val: any): any
{
    yield PushAPIHelper.call().pushNotification();
}

/**
 * Push通知(全ユーザー)の送信要求を投げる
 * @param val 
 */
export function* PushAllNotification(val: any): any
{
    yield PushAPIHelper.call().pushAllNotification();
}

/**
 * Subscriptionの登録解除
 */
export function* UnSubscribe(): any
{
    yield PushAPIHelper.call().unSubscribe();
}

export function* RemoveAllSubscription(): any
{
    yield PushAPIHelper.call().removeAllSubscription();
}