import dayjs from 'dayjs'

export type addType = 
    'day' | 'week' | 'month' | 'year' |
    'hour' | 'minute' | 'second';

export type dateFormat = {
    date        : string;
    datetime    : string;
    time        : string;
    [key: string] : string;
};

export class DatetimeService
{
    private static instance: DatetimeService;
    private FORMAT_LIST: dateFormat = {
        date        : 'YYYY/MM/DD',
        datetime    : 'YYY/MM/DD HH:mm:ss',
        time        : 'HH:mm:ss',
    };

    private FORMAT = 'date';
    private DATE: any = dayjs();

    public static call(): DatetimeService
    {
        if (!DatetimeService.instance) {
            DatetimeService.instance = new DatetimeService();
        }
        return DatetimeService.instance;
    }

    /**
     * 日付フォーマットの設定
     *  
     * @param format string [date, datetime, time]
     */
    public setFormat(format: string): DatetimeService
    {
        if (Object.prototype.hasOwnProperty.call(this.FORMAT_LIST, format)) {
            this.FORMAT = format;
        }

        return this;
    }

    /**
     * 日付を設定
     * 
     * @param date 
     */
    public setDate(date: string | number): DatetimeService
    {
        this.DATE = dayjs(date);
        return this;
    }

    /**
     * 日付処理モジュールを返す
     * 
     * @return dayjs
     */
    public callDate()
    {
        return dayjs;
    }

    /**
     * 現在時刻を返す
     * 
     * @return Date
     */
    public now()
    {
        return dayjs().format(this.FORMAT_LIST[this.FORMAT]);
    }

    /**
     * UnixTimeを返す
     * 未指定の場合現在時刻のUnixTimeを返す
     * 
     * @param date Date | null
     * @return number
     */
    public unix(date: Date | undefined = undefined): number
    {
        if (date === undefined) {
            return this.DATE.unix();
        } else {
            return dayjs(date).unix();
        }

    }

    /**
     * 時間の加算、減算結果を返す
     * 
     * @param day number [-10 or under ~ 10 or upper]
     * @param type addType [day, week, month, year, hour....etc]
     * @return Date
     */
    public calc(day: number, type: addType)
    {
        return this.DATE
            .add(day, type)
            .format(this.FORMAT_LIST[this.FORMAT]);
    }

    /**
     * 2つの日付の差を
     * 指定されたタイプ、日、月、年、時で計算し返す
     * 比較対象の日付がnullの場合、setDateで指定された日付
     * もしくは今の日付を比較を行う
     * 
     * @param type addType [day, week, month, year, hour....etc]
     * @param date1 Date
     * @param date2 Date | null
     * @return number | string
     */
    public diff(
        type: addType = 'hour', date1: Date, date2: Date | null = null
    ): number | string {
        if (date2 === null) {
            const d1 = this.DATE;
            return d1.diff(date1, type);
        } else {
            const d1 = dayjs(date1);
            return d1.diff(date2, type);
        }
    }
    
}

