import { useDispatch } from 'react-redux';

// import component
import ServiceWorker from './_helper/ServiceWorker';
import Status from './Status'; 

const Test1 = ((): JSX.Element => {
    const dispatch = useDispatch();

    return (
        <div className="container">
            <ServiceWorker />
            <nav className="text-3xl font-bold underline">
                <h3>WebPush</h3>
            </nav>
            <br></br>
            <section>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <button
                            id="btnPushMessage"
                            className='btn btn-dark'
                            onClick={() => {
                                // const p = getUserParam();
                                dispatch({
                                    type    : 'Server/updateFormParam',
                                    mail    : '',
                                    name    : '',
                                    group   : 0
                                });
                                dispatch({
                                    type    : 'PushAPIAction/subscribe'
                                });
                            }}>
                        登録
                        </button>
                    </div>
                    <div className="col">
                        <button
                            id="btnUnsubscribe"
                            className='btn btn-info'
                            onClick={() => {
                                dispatch({
                                    type    : 'PushAPIAction/unSubscribe'
                                });
                            }}>
                        購読を解除する
                        </button>
                    </div>
                    <div className="col">
                        <button
                            id="btnUnsubscribe"
                            className='btn btn-info'
                            onClick={() => {
                                dispatch({
                                    type    : 'PushAPIAction/test'
                                });
                            }}>
                        直接Notification出力
                        </button>
                    </div>
                </div>
            </div>
            </section>

            <section>
                <Status />
            </section>
            <section>
                <ul>
                    <li>ServiceWorker登録</li>
                    <li>サーバーから公開鍵取得</li>
                    <li>Subscription作成（JWT）</li>
                    <li>Subscriptionをブラウザに登録</li>
                    <li>Subscriptionをサーバーに登録</li>
                    <li>サーバー側でSubscriptionのendpointにPushメッセージ送信</li>
                </ul>
            </section>
        </div>

    );
})


export default Test1;
