import { useDispatch, useSelector } from 'react-redux';

// import component
import ServiceWorker from './_helper/ServiceWorker';
import ShowServer from './_helper/ShowServer';
import Status from './Status'; 

// import reducer
import { ServerPropsInterface, initialState } from '../reducers/Server';

const Group = ((): JSX.Element => {
    const dispatch = useDispatch();
    const server = useSelector((state: ServerPropsInterface) => {
        return (state.Server) ? state.Server : initialState;
    })

    return (
        <div className="container">
            <ServiceWorker />
            <nav className="text-3xl font-bold underline">
                <h3>WebPush</h3>
            </nav>
            <br></br>
            <div className="d-flex">
                <div className="my-box w-75">
                    <section>
                    所属ぐる～ぷ　:　 
                    <input
                        id='userGroup'
                        type='number'
                        className="form-control"
                        onChange={(e: any) => {
                            dispatch({
                                type    : 'Server/updateGroup',
                                group   : e.target.value
                            });
                        }}
                        value={server.group}
                        />
                    </section>
                    <br></br>
                    <section>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <button
                                    id="btnPushMessage"
                                    className='btn btn-dark'
                                    onClick={() => {
                                        dispatch({
                                            type    : 'PushAPIAction/subscribe'
                                        });
                                    }}>
                                サブスク登録
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    id="btnUnsubscribe"
                                    className='btn btn-info'
                                    onClick={() => {
                                        dispatch({
                                            type    : 'PushAPIAction/unSubscribe'
                                        });
                                    }}>
                                サブスク解除
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    id="btnUnsubscribe"
                                    className='btn btn-info'
                                    onClick={() => {
                                        dispatch({
                                            type    : 'PushAPIAction/setUserStatus'
                                        });
                                    }}>
                                グループ登録
                                </button>
                            </div>
                            <div className="col">
                                <button
                                    id="btnUnsubscribe"
                                    className='btn btn-info'
                                    onClick={() => {
                                        dispatch({
                                            type    : 'InfomationAction/getSubscription'
                                        });
                                    }}>
                                ﾌﾞﾗｳｻﾞのｻﾌﾞｽｸ取得
                                </button>
                            </div>
                        </div>
                    </div>
                    </section>
                    <section>
                        <ShowServer />
                    </section>
                    <section>
                        <Status />
                    </section>
                </div>
                <div className="my-box w-25">
                    <ul>
                        <li>グループを選ぶ（数値）</li>
                        <li>サブスク登録クリック</li>
                        <li>グループ登録クリック</li>
                        <li>PUSHを適当に試験</li>
                        <li>サブスク解除で登録解除</li>
                        <li>現状を見るならサブスク取得</li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </div>
    );
})

export default Group;
