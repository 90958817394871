import { createSlice } from '@reduxjs/toolkit';

export interface StatePropsInterface
{
    State?: [StateInterface],
    dispatch?   : any;
}

export interface StateInterface
{
    status  : 'info' | 'error' | 'warning';
    time    : number;
    body    : string;
}

export const initialState: [StateInterface] = [{
    status  : 'info',
    time    : 11111111111,
    body    : 'initial'
}]

const slice = createSlice({
    name: 'State',
    initialState,
    reducers: {
        addState: (state: any, action: any) => {
            if (state[0]['body'] === 'initial') {
                return [action.State];
            }
            return state.concat(action.State);
        },
        delState: (state: any, action: any) => {
            return state.Status.splice(action.index, 1);
        },
        reset: (state: any, action: any): [StateInterface] => {
            return initialState;
        }
    }
});

export default slice.reducer;
