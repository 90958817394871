import { useSelector } from 'react-redux';

import { StatePropsInterface, initialState as statusInitial } from '../reducers/State';

/**
 * スタックされたステータス情報を一覧で表示
 * 
 * 
 * @returns JSX.Element
 */
const Status = ((): JSX.Element => {
    const s = useSelector((state: StatePropsInterface) => {
                    return (state.State) ? state.State : statusInitial;
                });

    return (
        <div className="container">
            <section>
                <ul className='flex flex-rows p-2 bg-red-500 font-bold'>
                    { showStatus(s) }
                </ul>
            </section>
        </div>

    );
})

const showStatus = (status: any): JSX.Element[] => {
    if (status[0] === statusInitial[0]) {
        return [];
    }
    const _lists = Object.keys(status).map((val, key): any => {
        if (status[key]) {
            return (
                <li key={key} className="list-group-item message-content">
                    Type : { ('status' in status[key]) ? status[key]['status'] : '' }<br/>
                    Mess : { ('body' in status[key]) ? status[key]['body'] : '' }
                    <button>削除</button>
                </li>
            );
        }
        return;
    });
    return _lists;
}


export default Status;
