import { Link } from "react-router-dom";

const Home = ((): JSX.Element => {
    return (
        <div className="container">
            <br></br>
            <nav className="text-3xl font-bold underline">
                <h4>顔芸強制配信システム「届けこのウザい」</h4>
            </nav>
            <br></br>
            <div className="d-flex">
                <div className="my-box w-25">
                    <div className="card" style={{"width":"18rem"}}>
                        <div className="card-header">
                            <Link to="/mini" className="link-dark large_link">Push Uzai</Link>
                        </div>
                        <div className="card-body">
                            <Link to="/mini" className="link-dark large_link">
                                最小構成でPUSH配信
                            </Link>
                        </div>
                        <div className="card-footer">
                            <Link to="/mini" className="link-dark large_link">Push Uzai</Link>
                        </div>
                    </div>
                </div>
                <div className="my-box w-25">
                    <div className="card" style={{"width":"18rem"}}>
                            <div className="card-header">
                                <Link to="/group" className="link-dark large_link">Push Uzai</Link>
                            </div>
                            <div className="card-body">
                                <Link to="/group" className="link-dark large_link">
                                    グループ間でPUSH配信
                                </Link>
                            </div>
                            <div className="card-footer">
                                <Link to="/group" className="link-dark large_link">Push Uzai</Link>
                            </div>
                        </div>
                </div>
                <div className="my-box w-25">
                    <div className="card" style={{"width":"18rem"}}>
                            <div className="card-header">
                                <Link to="/user" className="link-dark large_link">Push Uzai</Link>
                            </div>
                            <div className="card-body">
                                <Link to="/user" className="link-dark large_link">
                                    相手を指定してPUSH配信
                                </Link>
                            </div>
                            <div className="card-footer">
                                <Link to="/user" className="link-dark large_link">Push Uzai</Link>
                            </div>
                        </div>
                </div>
            </div>
            <section>

            </section>
        </div>
    );
})

export default Home;
