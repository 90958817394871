import Server from './Server';
import State from './State';
import Worker from './Worker';

export const reducer = {
    State       : State,
    Server      : Server,
    Worker      : Worker,
}



