import { useSelector } from 'react-redux';

// import reducer
import { ServerPropsInterface, initialState } from '../../reducers/Server';

const ShowServer = ((): JSX.Element => {
    const server = useSelector((state: ServerPropsInterface) => {
        return (state.Server) ? state.Server : initialState;
    })
    return (
        <div className="container">
            <table className="table table-sm table-borderless">
                <tr><th>URL</th><th>{server.url}</th></tr>
                <tr><td>Group</td><td>{server.group}</td></tr>
                <tr><td>Name</td><td>{server.name}</td></tr>
                <tr><td>Mail</td><td>{server.mail}</td></tr>
                <tr><td>Body</td><td>{server.body}</td></tr>
                <tr><td>Key</td><td>{server.KEY}</td></tr>
            </table>
        </div>

    );
})

export default ShowServer;
