import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WorkerPropsInterface } from '../../reducers/Worker'

const ServiceWorker = ((): JSX.Element => {
    const dispatch = useDispatch();
    const w = useSelector((state: WorkerPropsInterface) => state.Worker);
    if (w?.register !== undefined && w?.register === true) {

    }
    useEffect(() => {
        // service worker 登録
        dispatch({
            type    : 'PushAPIAction/setServiceWorker'
        });
    }, []);

    return (
        <div></div>
    );
})


export default ServiceWorker;
