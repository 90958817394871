import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PushAPIHome from './components/PushAPIHome';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

render(
  <BrowserRouter>
    <Routes>
        <Route path="/" element={ <PushAPIHome page="home" /> }></Route>
        <Route path="/group" element={ <PushAPIHome page="group" /> }></Route>
        <Route path="/mini" element={ <PushAPIHome page="mini" /> }></Route>
        <Route path="/user" element={ <PushAPIHome page="user" /> }></Route>
    </Routes>
  </BrowserRouter>
  , document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
