import { createSlice } from '@reduxjs/toolkit';

export interface ServerPropsInterface
{
    Server?: ServerInterface,
    dispatch?   : any;
}

export interface ServerInterface
{
    url     : string;
    KEY     : string;
    mail    : string;
    name    : string;
    group   : number;
    body    : any;
}

export const initialState: ServerInterface = {
    // url     : 'http://localhost:8080',
    url     : 'https://pushapi.yauskosan.net/api',
    KEY     : '',
    mail    : '',
    name    : '',
    group   : 1,
    body    : '',
}

const slice = createSlice({
    name: 'Server',
    initialState,
    reducers: {
        setKEY: (state: any, action: any) => {
            return Object.assign({}, state,
                {
                    KEY     : action.KEY,
                }
            )
        },
        updateFormParam: (state: any, action: any) => {
            return Object.assign({}, state,
                {
                    mail    : action.mail,
                    name    : action.name,
                    group   : action.group,
                }
            )
        },
        updateGroup: (state: any, action: any) => {
            return Object.assign({}, state,
                {
                    group     : action.group,
                }
            )
        },
        updateName: (state: any, action: any) => {
            return Object.assign({}, state,
                {
                    name     : action.name,
                }
            )
        },
        updateMail: (state: any, action: any) => {
            return Object.assign({}, state,
                {
                    mail     : action.mail,
                }
            )
        },
        updateURL: (state: any, action: any) => {
            return Object.assign({}, state,
                {
                    url     : action.url,
                }
            )
        },
        updateBody: (state: any, action: any) => {
            return Object.assign({}, state,
                {
                    body    : action.body,
                }
            )
        },
        resetForm: (state: any, action: any) => {
            return Object.assign({}, state, initialState)
        }
    }
});

export default slice.reducer;
