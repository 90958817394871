import { PushAPIService } from '../_lib/notice/pushapi.service';
import { DatetimeService } from '../_lib/datetime/datetime.service';

export class PushAPIHelper
{

    private static instance: PushAPIHelper;

    public static call(): PushAPIHelper
    {
        if (!PushAPIHelper.instance) {
            PushAPIHelper.instance = new PushAPIHelper();
        }
        return PushAPIHelper.instance;
    }

    public async setServiceWorker(): Promise<boolean>
    {
        return await PushAPIService.call().setServiceWorker();
    }

    /**
     * サーバーから公開鍵受け取り
     * @returns Promise<void>
     */
    public async getServerKey(): Promise<Response>
    {
        return await PushAPIService.call()
                        .doFetch(
                            '/getkey',
                            {}
                        );
    }

    /**
     * サーバーから公開鍵を取得しSubscriptionを作成・登録
     * @param key string 公開鍵
     * @param url string サーバーURL
     * 
     */
    public async setup(key: string, url: string): Promise<void>
    {
        await PushAPIService.call()
                .setServerKey(key)
                .setURL(url)
                .subscribe('/subscribe');
    }

    public async updateUser(
        param: {name: string, mail: string, group: number}
    ): Promise<boolean> {
        const result = await PushAPIService.call().doFetch(
            '/updateuser',
            {
                subscription : await PushAPIService.call().getSubscription(),
                user         : param
            }
        );
        return (result) ? true : false;
    }

    /**
     * 自分へのメッセージの送信要求
     */
    public async pushNotification(): Promise<void>
    {
        try {
            await PushAPIService.call().loadSubscription();
            await PushAPIService.call()
                    .doFetch(
                        '/pushNotification',
                        await PushAPIService.call().getSubscription(false)
                    );
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * 全クライアントへのメッセージ送信要求
     */
    public async pushAllNotification()
    {
        try {
            await PushAPIService.call().doFetch('/pushAllNotification', {});
            console.log("send POST /pushAllNotification");
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * PushAPI登録解除
     * @returns 
     */
    public async unSubscribe()
    {
        try {
            if (!await PushAPIService.call().unSubscrive()) {
                return;
            }
            await PushAPIService.call()
                    .doFetch(
                        '/unsubscribe',
                        await PushAPIService.call().getSubscription(false)
                    );
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * 全てのクライアント登録を解除（サーバー側のみ）
     */
    public async removeAllSubscription()
    {
        const result = await PushAPIService.call().doFetch('/removePushSubscriptions', {});
        const resJson = await result.json();
        if (resJson.success) {
            console.log("すべての PushSubscription を削除しました。");
        } else {
            console.log("PushSubscription の削除に失敗しました。");
        }
    }


    public getNow(): number
    {
        return DatetimeService.call().unix();
    }
}
