

/**
 * base64URLをUint8Arrayに変換
 * @param base64String string base64文字列
 * @returns Uint8Array
 */
export const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4),
      base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/"),
      rawData = window.atob(base64),
      outputArray = new Uint8Array(rawData.length);
    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}