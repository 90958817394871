import { PushAPIService } from '../_lib/notice/pushapi.service';
import { DatetimeService } from '../_lib/datetime/datetime.service';

export class InfomationHelper
{

    private static instance: InfomationHelper;

    public static call(): InfomationHelper
    {
        if (!InfomationHelper.instance) {
            InfomationHelper.instance = new InfomationHelper();
        }
        return InfomationHelper.instance;
    }

    public async getSubsctiption(): Promise<string>
    {
        return JSON.stringify(
                    await PushAPIService.call().getSubscription()
                );
    }

    public getNow(): number
    {
        return DatetimeService.call().unix();
    }
}
