import { put, takeEvery } from 'redux-saga/effects';

// import Helper
import { PushAPIHelper } from '../_helper/pushapi.helper';
import { InfomationHelper } from '../_helper/infomation.helper';

// Root Saga登録配列
export const RootInfomationAction = [
    takeEvery('InfomationAction/getSubscription' , getSubscription),
];

/**
 * ブラウザに登録されているSubscriptionを取得する
 */
export function* getSubscription(): any
{
    yield addStatus(
        'info',
        PushAPIHelper.call().getNow(),
        yield InfomationHelper.call().getSubsctiption(),
    );
}

function* addStatus(st: string, time: number, body: string): any
{
    yield put({
        type    : 'State/addState',
        State   : {
            status  : st,
            time    : time,
            body    : body
        }
    });
}
