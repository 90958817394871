import { urlBase64ToUint8Array } from '../_helper/convert.helper';

export class PushAPIService
{
    private static instance: PushAPIService;
    private SERVER_KEY  : string = '';
    private URL         : string = 'http://localhost:8080';
    private Subscription: PushSubscription | any;
    private Register: ServiceWorkerRegistration | any;

    private MessageChannel: MessageChannel | any;

    public static call(): PushAPIService
    {
        if (!PushAPIService.instance) {
            PushAPIService.instance = new PushAPIService();
        }
        return PushAPIService.instance;
    }

    /**
     * ユーザーにServiceWorkerの登録許可を申請
     * @param path 
     * @returns 
     */
    public async setServiceWorker(): Promise<boolean>
    {
        if (!this.checkBrowser()) return false;
        try {
            this.Register = await navigator.serviceWorker.register(
                "service.worker.js"
            );
    
            if (Notification.permission === "denied") {
                return false;
            } else {
                // ブロックされていなければ、購読処理を実行する
                // await this.subscribe(this.Register, path);
                return true;
            }
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    /**
     * PushSubscription を取得する
     * [通知]設定がデフォルトの場合は、ここで「許可 or ブロック」を聞いてくる
     * ブロックが選択された場合、この時点で例外が発生する
     * @param register 
     * @returns 
     */
    public async subscribe(
        path:string
    ): Promise<boolean> {
        if (!this.Register) return false;
        if (this.SERVER_KEY === '') return false;

        try {
            this.Subscription = await this.Register.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(this.SERVER_KEY)
            });
            console.log(this.Subscription);
            // PushSubscription をサーバーに送る
            await this.doFetch(path, this.Subscription);
            console.log("PushSubscription が Application Server に送信されました。");
        } catch (err: any) {
            if (err.name === 'NotAllowedError') {
                console.log("通知がブロックされました。");
            }
            console.error('Error name:', err.name);
            console.error('Error message:', err.message);
            return false;
        }
        return true;
    }

    /**
     * サーバー公開鍵を変数に格納
     * @param key 
     * @returns 
     */
    public setServerKey(key: string): PushAPIService
    {
        this.SERVER_KEY = key;
        return this;
    }

    /**
     * サーバーURLを変更
     * @param url string サーバーURL
     * @returns PushAPIService
     */
    public setURL(url: string): PushAPIService
    {
        this.URL = url;
        return this;
    }

    /**
     * Subscription情報を返す
     * 無い場合は新規作成
     * @param toString 
     * @returns 
     */
    public async getSubscription(toString: boolean = false): Promise<PushSubscription>
    {
        if (!this.Subscription) {
            await this.loadSubscription();
        }

        return (toString) ? JSON.stringify(this.Subscription) : this.Subscription;
    }

    /**
     * Subscribe登録解除
     * @returns Promise<boolena>
     */
    public async unSubscrive(): Promise<boolean>
    {
        try {
            if (!await this.loadSubscription()) {
                return false;
            }
            // const regist = await this.Register.unregister();
            const result = await this.Subscription.unsubscribe();
            if (result) {
                console.log("登録解除が成功しました");
                return true;
            }
            console.log("登録解除が失敗しました");
            return false;
            
        } catch (err) {
            console.error(err);
            return false;
        }
    }

    /**
     * サーバーをコール
     * @param path string アクセスするエンドポイント
     * @param body any 送信要素(だいたい文字列)
     * @returns 
     */
    public async doFetch(path: string, body: any): Promise<Response>
    {
        return await fetch(this.URL + path, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    /**
     * ブラウザがサービスワーカーをサポートしているかチェック
     * @returns boolean
     */
    public checkBrowser(): boolean
    {
        if ('serviceWorker' in navigator === false) {
            return false;
        }
        /*
        if ('PushMessager' in window === false) {
            throw new Error('PushMessage is not supported');
        }*/
        return true;
    }

    /**
     * ブラウザにSubscriptionが既に登録されているか確認
     * 
     * 既に存在している場合は、Subscriptionを変数に格納
     * @returns Promise<boolean>
     */
    public async loadSubscription(): Promise<boolean>
    {
        const register = await navigator.serviceWorker.ready.then();
        this.Subscription = await register.pushManager.getSubscription();
        if (!this.Subscription) {
            console.log("有効な PushSubscription がありません。");
            return false;
        }
        return true;
    }

    /**
     * serviceworkerと接続
     * @returns Promise<any>
     */
    public async connectServiceWorker(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if (navigator.serviceWorker.controller === null) {
                console.error("navigator.serviceWorker.controller is null");
                return;
            }
            this.MessageChannel = new MessageChannel();
            this.MessageChannel.port1.onmessage = resolve;
        })
    }
 
    /**
     * servicewakerと通信
     * ブラウザ側から繋がないと相互通信は出来ない
     * @param message any 基本的に文字列
     * @returns Promise<any> service workerから戻りが合った場合だけ返る
     */
    public callServiceWorker(message: any): void
    {
        if (navigator.serviceWorker.controller === null) {
            return;
        }
        navigator.serviceWorker.controller.postMessage(message, [this.MessageChannel.port2]);
    }

}