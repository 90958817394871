import { all } from 'redux-saga/effects';

import { RootPushAPIAction } from './pushapi.action';
import { RootInfomationAction } from './infomation.action';

// Load AnimationAction

export default function* rootSaga() {
    yield all([
        ...RootPushAPIAction,
        ...RootInfomationAction,
    ]);
}