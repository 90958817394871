import { Provider } from 'react-redux';
import { createStore } from '../store/configureStore';
import Home from './Home';
import Test1 from './Test1';
import Group from './Group';
import ToUser from './ToUser';

interface PATH {
    page: string
}

const store = createStore();

const PushAPIHome = (path: PATH): JSX.Element => {
    return (
        <Provider store={ store }>
            { ChangePage(path.page) }
        </Provider>
    )
}

const ChangePage = (path: string) => {
    if (path === 'home') return <Home />;
    if (path === 'mini') return <Test1 />;
    if (path === 'group') return <Group />;
    if (path === 'user') return <ToUser />;
}

export default PushAPIHome;