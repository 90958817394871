import { createSlice } from '@reduxjs/toolkit';

export interface WorkerPropsInterface
{
    Worker?: WorkerInterface,
    dispatch?   : any;
}

export interface WorkerInterface
{
    call            : string;
    return          : string;
    register        : boolean;
    subscription    : boolean;
}

export const initialState: WorkerInterface = {
    call            : '',
    return          : '',
    register        : false,
    subscription    : false,
}

const slice = createSlice({
    name: 'Worker',
    initialState,
    reducers: {
        setCall: (state: any, action: any) => {
            return Object.assign({}, state,
                {
                    call     : action.call,
                }
            )
        },
        setReturn: (state: any, action: any) => {
            return Object.assign({}, state,
                {
                    return     : action.return,
                }
            )
        },
        toggleRegister: (state: any, action: any) => {
            return Object.assign({}, state,
                {
                    register     : action.register,
                }
            )
        },
        toggleSubscription: (state: any, action: any) => {
            return Object.assign({}, state,
                {
                    subscription : action.subscription,
                }
            )
        },
        reset: (state: any, action: any) => {
            return initialState
        }
    }
});

export default slice.reducer;
